.ask-me-anything-info_section {
    position: absolute;
    background: #333739;
    border: 1px solid rgba(89, 94, 104, 0.8);
    box-sizing: border-box;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 16px;
    left: 30px;
    width: 350px;
    z-index: 99;
    top: 40px;
    p {
        color: #FFF;
        font-family: Overpass;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        opacity: 0.7;
    }
  }
  .ask-me-anything-header {
    display: flex;
    align-items: center;
    h2 {
        margin-right: 10px;
    }
  }
  .ask-me-anything-question-lists {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 15px;
    border-bottom: 1px solid #393939;
    .ask-me-anything-questions {
        .ask-me-anything-question-txt{ 
            color: #FFF;
            font-family: Overpass;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 7px;
        }
        .ask-me-anything-date{
            color: #FFF;
            font-family: Overpass;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            opacity: 0.6;
        }
    }
    .ask-me-anything-action{
        .ar-ask-me-anything-submitted-btn {
            background: rgba(62, 207, 11, 0.15);
            border: none;
            padding: 6px 14px;
            color: #3ECF0B;
            font-family: Overpass;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.6px;
            height: 28px;
            border-radius: 5px;
        }
        .ar-ask-me-anything-answer-btn {
            border: 1px solid #FD7663;
            background: transparent;
            border-radius: 50px;
            padding: 10px 33px;
            color: #FD7663;
            font-family: Overpass;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: -0.6px;
            height: 40px;
            &:hover{
                color: #ffffff;
                background: #FD7663;
            }
        }
    }
    &:last-child{
        border: none;
    }
}

.ask-me-anything-answer-form-file-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    background: #333739;
    padding: 10px;
    border-radius: 15px;
    border: none;
    margin: 10px 0;
    img {
        width: 36px;
        border-radius: 5px;
        height: 36px;
        margin-right: 10px;
        object-fit: cover;
        cursor: pointer;
    }
    span{
        color: #FFF;
        font-family: Overpass;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.64px;
        text-transform: lowercase;
        width: 195px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    button {
        border: none;
        background: rgba(255, 255, 255, 0.2);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 8px;
    }
}

.ask-me-anything-submited-answer-box {
    padding: 20px 0;
}
.ask-me-anything-answer-form-container {
    padding: 20px 0;
}    
.ask-me-anything-question-txt {
    color: #FFF;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 7px;
}

.ask-me-anything-question-date {
    color: #FFF;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    opacity: 0.6;
}

.ask-me-anything-answer-txt {
    color: #FFF;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px 0;
    word-break: break-word;
}

.ask-me-anything-fill-btn {
    border-radius: 10px;
    border: 1px solid #FD7663;
    background: transparent;
    height: 45px;
    color: #fff;
    background: #FD7663;
    text-align: center;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 75% */
    letter-spacing: -0.48px;
    width: 170px;
}
.ask-me-anything-outline-btn {
    border-radius: 10px;
    border: 1px solid #666;
    background: transparent;
    height: 45px;
    color: #666;
    text-align: center;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 75% */
    letter-spacing: -0.48px;
    width: 170px;
}
.ask-me-anything-answer-form-file-choose{
    label{
        border: dashed 2px #666;
        min-width: 350px;
        max-width: 350px;
        background: rgba(51, 55, 57, 0.40);
        svg{
            margin-right: 10px;
            path {
                fill: #6d6c6c;
            }
        }
        .hXAsBl {
            span {
                color: rgba(255, 255, 255, 0.50);
                font-family: Overpass;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.7px;
            }
        }
        .dAjkaY{
            span {
                text-decoration: underline;
                color: #FD7663;
                font-family: Overpass;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.7px;
            }
        }
    }
}
.all-ans-media-list {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    .answers-medias {
        width: auto;
        height: 162px;
        flex-shrink: 0;
        max-width: 243px;
        display: flex;
        justify-content: center;
        align-items: start;
        img {
            object-fit: contain;
            width: 100%;
            height: -webkit-fill-available;
        }
    }
}
.ask-me-anything-answer-form-textarea {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    gap: 5px;
    textarea {
        width: 100%;
        border: 1px solid rgba(102, 102, 102, 0.40);
        background: #333739;
        padding: 20px;
        border-radius: 10px;
        margin: 0;
        height: 200px;
        resize: none;
        color: #fff;
        font-family: Overpass;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        &:focus-visible {
            outline: none;
        }
    }
    .error {
        margin: 2px 0 0;
        color: #b70000;
        font-size: 14px;
    }
}
.modal-open.modal.fade{
    background: rgba(0, 0, 0, 0.7) !important;
}
.ar-under-review-popup{
    width: 360px !important;
    height: 243px;
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    .modal-content{
        background: #333739;
        padding: 40px;
        text-align: center;
        .modal-header{
            display: none;
        }
        .modal-body{
            margin: 0 auto;
            width: 100%;
            padding: 0;
            img{
                width: 96.36px;
                height: 96.36px;
                flex-shrink: 0;
            }
            h3{
                margin-bottom: 10px;
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.6px;
            }
            p{
                margin: 0;
                color: #C8C8C8;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 125% */
                letter-spacing: -0.8px;
            }
        }
    }
}